





















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































::v-deep .el-table {
    th {
        padding: 0px ;
    }
    thead{
        height: 15px !important;
        color:rgb(71, 70, 70);
        font-size:12px;
    }
    td {
        padding: 3px ;
        font-size:11px;
    }   
}
.imgcss{
    border:1px solid #5cadff;
    width:90px;
    height:80px;
    display:flex;
    flex-direction:column;
    text-align:center;
    margin-right:18px;
    margin-left:10px;
    margin-top:10px;
    margin-bottom:5px;
}
.imgcss:hover{
    cursor: pointer;
    border:1px solid #409EFF;
    z-index: 1;
    .picsrch{
        right:3px;
        top:1px; 
        z-index: 99;
        display:inline;
    }
    .fileset{
        left:3px;
        top:1px; 
      //  position:absolute;
        display:inline;
    }
}
.picsrch{
    right:3px;
    top:1px; 
    z-index: 99;
    display:none;
}
.fileset{
    left:3px;
    top:1px; 
   // position:absolute;
    display:none;
}
.item-width  {
    width: 32%;
    color:#409EFF;
}
.pub_dialog {
    display: flex;
    justify-content: center;
    align-items: Center;
    overflow: hidden;
    .el-dialog {
        margin: 0 auto !important;
        height: 90%;
        overflow: hidden;
        .el-dialog__body {
            position: absolute;
            left: 0;
            top: 54px;
            bottom: 0;
            right: 0;
            padding: 0;
            z-index: 1;
            overflow: hidden;
            overflow-y: auto;
        }
    }
}
