









































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.messageBox .el-message-box__content{
    border-top: 1px solid #EBEEF5;
    height: 50px;
}
.el-message-box__message p{
    line-height: 25px;
}
.messageBox .el-message-box__btns{
    padding: 20px 15px 0;
}
.messageBox .el-message-box__header{
    background:#409eff;
}
.messageBox .el-message-box__title{
    color:white ;
    font-size:14px;
}
.row-width  {
    width: 98.5%;
}
.el-card__header{
    //height:35px;
    vertical-align: middle;
}

.el-card__header  {
    height:40px;
}
.el-dialog__body{
    padding: 10px;
}
.el-dialog__header{
    padding: 0px;
}
.el-dialog__footer{
    padding: 5px;
    border-top:1px solid #ccc;
} 
.el-dialog__headerbtn{font-size: 20px; background:white;top:5px !important;}
.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after{
    border-color :#3d77eb;
}
.el-checkbox__input.is-disabled+span.el-checkbox__label{
    color:#3d77eb
}
